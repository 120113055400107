@import 'src/assets/scss/var';

header {
  top: 0;
  padding: 0 1rem;
  position: sticky;
  z-index: 1;
}

.images-container {
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  .image-wrapper {
    position: relative;

    .image-preview {
      width: 5rem;
      height: 5rem;
      border-radius: 0.25rem;
      object-fit: cover;
    }

    .overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: $white;
      opacity: 0;
      cursor: pointer;
      display: flex;
      position: absolute;
      font-size: 1.5rem;
      border-radius: 0.25rem;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:hover .overlay {
      opacity: 1;
    }
  }
}
