@import 'src/assets/scss/var';
@import 'bootstrap/dist/css/bootstrap.min.css';

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.content-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullscreent-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100svh;
  width: 100%;
  background-color: $black03;
}

// bootstrap
.fluid-container {
  overflow-x: hidden;
}
